import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import { watchViewport, unwatchViewport } from 'tornis'
import { setHeaderTheme } from '../actions/headerTheme'
import onPageRender from '../hocs/onPageRender'
import InfoPageSectionTitle from '../components/InfoPageSectionTitle/InfoPageSectionTitle'
import InfoPageSectionDescription from '../components/InfoPageSectionDescription/InfoPageSectionDescription'
import Footer from '../components/Footer/Footer'
import Head from '../components/Head/Head'
import InfoPageCv from '../components/InfoPageCv/InfoPageCv'

const colorsByIndex = {
  0: {
    background: '',
    text: 'text-red-100',
    headerTheme: 'grayWithBg',
  },
  1: {
    background: 'bg-brown-300',
    text: 'text-gold-100',
    headerTheme: 'goldOnDarkWithBg',
  },
  2: {
    background: 'bg-gold-100',
    text: 'text-brown-200',
    headerTheme: 'goldWithBg',
  },
}

const classes = {
  section: [
    'relative',
    'min-h-screen',
    'py-12',
    'sm:py-24',
    'xl:py-32',
    'px-3',
    'sm:px-6',
    //
  ].join(' '),
  grid: [
    'md:flex',
    //
  ].join(' '),
  column: [
    'md:w-3/4',
    'md:ml-auto',
    '2xl:w-2/3',
    'md:pr-6',
    //
  ].join(' '),
  smallTitle: [
    'mb-12',
    'font-serif-display',
    'uppercase',
    'tracking-tight',
    'text-2xl',
    'sm:text-4xl',
    'md:text-5xl',
    //
  ].join(' '),
  nameAndSurnames: [
    'leading-none',
    'font-serif-display',
    'uppercase',
    'tracking-tight',
    'text-2xl',
    'sm:text-4xl',
    'md:text-5xl',
    'lg:text-6xl',
    // 'text-center',
    // 'md:text-left',
    //
  ].join(' '),
  birthplace: [
    'mt-2',
    'mb-8',
    'md:mb-20',
    'lg:mb-24',
    'leading-none',
    'font-sans-display',
    'sm:text-xl',
    'md:text-2xl',
    'lg:text-3xl',
    // 'text-center',
    // 'md:text-left',
    //
  ].join(' '),
  clientsGrid: [
    'mb-40',
    'lg:mb-20',
    'lg:flex',
    'items-baseline',
    //
  ].join(' '),
  clientsGridCol: [
    'lg:w-1/2',
    //
  ].join(' '),
  clientsList: [
    'font-sans-display',
    'md:text-2xl',
    'xl:text-3xl',
    //
  ].join(' '),
}

class InfoPage extends Component {
  constructor(props) {
    super(props)
    // query params scroll position
    this.watchScroll = this.watchScroll.bind(this)
    this.sections = {}
  }

  componentDidMount() {
    watchViewport(this.watchScroll)
  }

  componentWillUnmount() {
    unwatchViewport(this.watchScroll)
  }

  watchScroll({ scroll }) {
    if (!scroll.changed || !this.sections) return

    const { setHeaderTheme } = this.props
    const { body } = this.props.data.page.data
    const getSectionTop = i => this.sections[i].getBoundingClientRect().top
    // https://gist.github.com/davidtheclark/5515733#gistcomment-2853131
    const isInViewport = (
      e,
      { top: t, height: h } = e.getBoundingClientRect()
    ) => t <= window.innerHeight && t + h >= 0

    body.forEach((_, i) => {
      const section = this.sections[i]

      if (!section) return

      if (isInViewport(section) && getSectionTop(i) <= 0) {
        const { headerTheme } = colorsByIndex[i]
        setHeaderTheme(headerTheme)
      }
    })
  }

  render() {
    const { data, pageContext } = this.props
    const { lang } = pageContext
    const { page } = data
    const { fields } = page
    const { pagePath } = fields
    const { body } = page.data

    return (
      <div className='relative'>
        <Head path={pagePath} />
        {body.map(({ id, primary }, i) => {
          const {
            title,
            nameAndSurnames,
            birthplace,
            description,
            cv,
            press,
            clientsListTitle,
            clientsList,
          } = primary
          const bgColor = colorsByIndex[i].background
          const textColor = colorsByIndex[i].text

          let clientsLeft = null
          let clientsRight = null
          if (clientsList && clientsList.raw.length > 0) {
            const half = Math.floor(clientsList.raw.length / 2)
            clientsLeft = clientsList.raw.slice(0, half)
            clientsRight = clientsList.raw.slice(half, clientsList.raw.length)
          }

          return (
            <div
              key={id}
              className={[bgColor, classes.section].join(' ')}
              ref={el => (this.sections[i] = el)}
            >
              <InfoPageSectionTitle text={title.text} className={textColor} />
              <div className={classes.grid}>
                <div className={classes.column}>
                  {nameAndSurnames && (
                    <h1
                      className={[classes.nameAndSurnames, textColor].join(' ')}
                    >
                      {nameAndSurnames}
                    </h1>
                  )}
                  {birthplace && (
                    <div className={[classes.birthplace, textColor].join(' ')}>
                      {birthplace}
                    </div>
                  )}
                  {description && (
                    <InfoPageSectionDescription
                      html={description.html}
                      className={textColor}
                    />
                  )}
                  {cv && <InfoPageCv items={cv.raw} className={textColor} />}
                  {press && (
                    <div className='mt-12'>
                      <h3 className={[classes.smallTitle, textColor].join(' ')}>
                        Premsa
                      </h3>
                      <InfoPageSectionDescription
                        html={press.html}
                        className={textColor}
                      />
                    </div>
                  )}
                  {clientsList && clientsList.raw.length > 0 && (
                    <>
                      {clientsListTitle && (
                        <h3
                          className={[classes.smallTitle, textColor].join(' ')}
                        >
                          {clientsListTitle}
                        </h3>
                      )}
                      <div className={classes.clientsGrid}>
                        {clientsLeft && (
                          <div className={classes.clientsGridCol}>
                            <ul
                              className={[classes.clientsList, textColor].join(
                                ' '
                              )}
                            >
                              {clientsLeft.map(({ text }, i) => {
                                return <li key={i}>{text}</li>
                              })}
                            </ul>
                          </div>
                        )}
                        {clientsRight && (
                          <div className={classes.clientsGridCol}>
                            <ul
                              className={[classes.clientsList, textColor].join(
                                ' '
                              )}
                            >
                              {clientsRight.map(({ text }, i) => {
                                return <li key={i}>{text}</li>
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        })}
        <Footer theme='gold' lang={lang} />
      </div>
    )
  }
}

InfoPage.propTypes = {
  data: PropTypes.object.isRequired,
  setHeaderTheme: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTheme: theme => dispatch(setHeaderTheme(theme)),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(onPageRender(InfoPage))

export const query = graphql`
  query($id: String!) {
    page: prismicInfoPage(id: { eq: $id }) {
      data {
        body {
          ... on PrismicInfoPageBodySobreMi {
            id
            primary {
              title: section_title {
                text
              }
              nameAndSurnames: name_and_surnames
              birthplace
              description: about_description {
                html
              }
            }
          }
          ... on PrismicInfoPageBodySobreMi1 {
            id
            primary {
              title: section_title {
                text
              }
              cv {
                html
                raw
              }
              press {
                html
              }
            }
          }
          ... on PrismicInfoPageBodyClients {
            id
            primary {
              title: section_title {
                text
              }
              clientsListTitle: clients_list_title
              clientsList: clients_list {
                raw
              }
            }
          }
        }
      }
      fields {
        pagePath
      }
    }
  }
`
