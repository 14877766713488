import React from 'react'
import PropTypes from 'prop-types'
import styles from './InfoPageCv.module.scss'

const classes = {
  list: [
    'font-sans-display',
    'sm:text-xl',
    'lg:text-2xl',
    'xl:text-3xl',
    'leading-tight',
    //
  ].join(' '),
}

/**
 * @param {String} type
 * @param {String} content
 * @param {Object} data
 */
function createSpanOfType(type, content, data) {
  if (type === 'em') {
    return `<em>${content}</em>`
  } else if (type === 'hyperlink') {
    const { url } = data
    return `<a href="${url}">${content}</a>`
  }
}

const InfoPageCv = ({ items, className }) => {
  return (
    <ul className={[classes.list, styles.list, className].join(' ')}>
      {items.map(({ text, spans }, i) => {
        let str = text

        if (spans.length > 0) {
          str = spans.reduce((accumulator, currentItem) => {
            /**
             * NOTE: això no funcionarà si hi ha dos spans
             * diferents amb el mateix contingut
             */
            const { start, end, type, data } = currentItem
            const content = text.substring(start, end)
            const span = createSpanOfType(type, content, data)
            const str = accumulator.replace(content, span)
            return str
          }, text)
        }

        return (
          <li
            key={i}
            className={i < items.length - 1 ? 'mb-3 xl:mb-6' : ''}
            dangerouslySetInnerHTML={{ __html: str }}
          />
        )
      })}
    </ul>
  )
}

InfoPageCv.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default InfoPageCv
