import React from 'react'
import PropTypes from 'prop-types'
import styles from './InfoPageSectionTitle.module.scss'

const classes = {
  container: [
    'md:absolute',
    'md:top-0',
    'md:left-0',
    'md:mt-24',
    'xl:mt-32',
    'md:ml-6',
    //
  ].join(' '),
  title: [
    'mb-10',
    'font-serif-display',
    'leading-none',
    'tracking-tight',
    'uppercase',
    // 'text-center',
    'text-3xl',
    'sm:text-4xl',
    //
  ].join(' '),
}

const InfoPageSectionTitle = ({ text, className }) => {
  return (
    <div className={classes.container}>
      <h2 className={[classes.title, styles.title, className].join(' ')}>
        {text}
      </h2>
    </div>
  )
}

InfoPageSectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

InfoPageSectionTitle.defaultProps = {
  className: '',
}

export default InfoPageSectionTitle
