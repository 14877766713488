import React from 'react'
import PropTypes from 'prop-types'
import styles from './InfoPageSectionDescription.module.scss'

const classes = {
  content: [
    'font-sans-display',
    'sm:text-xl',
    'lg:text-2xl',
    'xl:text-3xl',
    'clearfix',
    //
  ].join(' '),
}

const InfoPageSectionDescription = ({ html, className }) => {
  return (
    <div
      className={[classes.content, styles.content, className].join(' ')}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

InfoPageSectionDescription.propTypes = {
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
}

InfoPageSectionDescription.defaultProps = {
  className: '',
}

export default InfoPageSectionDescription
